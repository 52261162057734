import React from 'react';
import './Footer.css'; // Certifique-se de que este arquivo existe
import '@fortawesome/fontawesome-free/css/all.min.css';

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer-container">
        {/* Seção da esquerda (Copyright) */}
        <div className="footer-left">
          <p>&copy; {new Date().getFullYear()} Acessoria Fit | CNPJ: 58.322.067/0001-22</p>
        </div>

        {/* Seção central (Links) */}
        <div className="footer-center">
          <nav className="footer-links">
            <a href="/sobre">Sobre Nós</a>
            <a href="/politicas">Políticas de Reembolso</a>
            <a href="/termos">Termos de Uso</a>
          </nav>
        </div>

        {/* Seção da direita (Ícones de redes sociais) */}
        <div className="footer-right">
          <span>Siga-nos:</span>
          <a 
            href="https://www.facebook.com" 
            target="_blank" 
            rel="noopener noreferrer"
            aria-label="Facebook"
          >
            <i className="fab fa-facebook-f" />
          </a>
          <a 
            href="https://www.instagram.com" 
            target="_blank" 
            rel="noopener noreferrer"
            aria-label="Instagram"
          >
            <i className="fab fa-instagram" />
          </a>
          <a 
            href="https://www.youtube.com" 
            target="_blank" 
            rel="noopener noreferrer"
            aria-label="YouTube"
          >
            <i className="fab fa-youtube" />
          </a>
        </div>
      </div>
    </footer>
  );
}