import React from 'react';
import './ListTileItem.css';
import { FaHeartbeat, FaAppleAlt, FaDumbbell, FaCheck } from 'react-icons/fa'; 

export default function ListTileItem({ item, isSelected, onToggle, isBaseItem }) {
  const handleItemClick = () => {
    if (!isBaseItem) {
      onToggle();
    }
  };

  const handleKeyDown = (e) => {
    if (!isBaseItem && (e.key === 'Enter' || e.key === ' ')) {
      e.preventDefault();
      onToggle();
    }
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
  };

  

  return (
    <li
      className={`list-tile-item ${isSelected ? 'selected' : ''} ${isBaseItem ? 'base-item' : ''}`}
      onClick={handleItemClick}
      onKeyDown={handleKeyDown}
      tabIndex={isBaseItem ? "-1" : "0"} // base item não precisa de foco para seleção
      style={isBaseItem ? { cursor: 'default' } : { cursor: 'pointer' }}
    >
      <div className="item-details">
        <span className="item-name">{item.descricao}</span>
        <div className="item-prices">
          <span className="item-price">
            {formatCurrency(item.valor_normal)}
          </span>
          <span className="item-price-off">
            {formatCurrency(item.valor_off || 0)}
          </span>
        </div>
      </div>
    </li>
  );
}