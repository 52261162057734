// src/components/LoadingSpinner.jsx

import React from "react";
import './LoadingSpinner.css'; // Importando o CSS específico do spinner

function LoadingSpinner() {
  return (
    <div className="spinner-container" role="status" aria-live="polite" aria-label="Carregando">
      <div className="spinnera">
        <span className="spinner-text">A</span>
      </div>
    </div>
  );
}

export default LoadingSpinner;