// Avaliacoes.jsx

import React, { useState, useEffect } from 'react';
import { FaStar, FaChevronLeft, FaChevronRight } from 'react-icons/fa'; // Importando ícones para os botões
import { motion, AnimatePresence } from 'framer-motion';
import './Avaliacoes.css'; // Importando o CSS específico

export default function Avaliacoes({ avaliacoes = [] }) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slides, setSlides] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [direction, setDirection] = useState(0);

  // Função para dividir as avaliações em slides
  const dividirEmSlides = (avaliacoes, porSlide) => {
    const slides = [];
    for (let i = 0; i < avaliacoes.length; i += porSlide) {
      slides.push(avaliacoes.slice(i, i + porSlide));
    }
    return slides;
  };

  // Função para calcular a diferença em dias entre dataCompra e hoje
  const calcularDias = (dataCompra) => {
    if (!dataCompra) return null; // Retorna null se dataCompra for inválida ou inexistente

    const hoje = new Date();
    const compra = new Date(dataCompra);

    // Verifica se a dataCompra é uma data válida
    if (isNaN(compra.getTime())) return null;

    const diffTime = hoje - compra;
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

    return diffDays;
  };

  // Atualiza os slides com base no tamanho da janela
  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth < 768;
      setIsMobile(mobile);
      const porSlide = mobile ? 1 : 3;
      setSlides(dividirEmSlides(avaliacoes, porSlide));
      setCurrentSlide(0); // Resetar para o primeiro slide ao redimensionar
    };

    handleResize(); // Chamada inicial

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [avaliacoes]);

  // Muda o slide a cada 5 segundos
  useEffect(() => {
    if (slides.length === 0) return;

    const interval = setInterval(() => {
      paginate(1);
    }, 5000); // 5000ms = 5 segundos

    return () => clearInterval(interval);
  }, [slides, currentSlide]);

  // Variantes para animação de slides
  const variants = {
    enter: (direction) => ({
      x: direction > 0 ? 300 : -300,
      opacity: 0,
    }),
    center: {
      x: 0,
      opacity: 1,
    },
    exit: (direction) => ({
      x: direction < 0 ? 300 : -300,
      opacity: 0,
    }),
  };

  // Função para paginar os slides manualmente
  const paginate = (newDirection) => {
    if (slides.length === 0) return;

    let nextSlide = (currentSlide + newDirection + slides.length) % slides.length;
    let adjustedDirection = newDirection;

    // Ajustar a direção ao fazer loop
    if (newDirection === 1 && currentSlide === slides.length - 1) {
      adjustedDirection = -1; // Direção reversa ao ir do último para o primeiro
    } else if (newDirection === -1 && currentSlide === 0) {
      adjustedDirection = 1; // Direção direta ao ir do primeiro para o último
    }

    setDirection(adjustedDirection);
    setCurrentSlide(nextSlide);
  };

  // Função para truncar texto
  const truncateText = (text, maxLength) => {
    if (!text) return 'Comentário não informado'; // Retorna uma string específica se o texto for null ou undefined
    return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
  };

  return (
    <section className="avaliacoes">
      <h2>O que Nossos Clientes Dizem</h2>
      <div className="avaliacoes-carousel">
        <AnimatePresence mode="wait" initial={false} custom={direction}>
          {slides.length > 0 && (
            <motion.div
              key={currentSlide}
              className="slide"
              custom={direction}
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                x: { type: 'spring', stiffness: 400, damping: 30 },
                opacity: { duration: 0.3 },
              }}
            >
              {slides[currentSlide].map((avaliacao) => {
                if (!avaliacao) {
                  console.error(`Avaliação está null ou undefined.`);
                  return null; // Ignora avaliações null
                }

                const diasCliente = calcularDias(avaliacao.data_compra);

                return (
                  <div className="avaliacao" key={avaliacao.id_avaliacao}>
                    <div className="header">
                      <h3 title={avaliacao.nome || 'Nome não fornecido'}>
                        {avaliacao.nome || 'Nome não fornecido'}
                      </h3>
                      {/* Exibir "Cliente há x dias" se diasCliente for válido */}
                      {diasCliente !== null && (
                        <p className="dias-cliente">
                          Cliente há {diasCliente} {diasCliente === 1 ? 'dia' : 'dias'}
                        </p>
                      )}
                    </div>
                    <div className="estrelas">
                      {[...Array(5)].map((_, i) => (
                        <motion.span
                          key={i}
                          initial={{ scale: 0, opacity: 0, color: '#e4e5e9' }}
                          animate={{
                            scale: 1,
                            opacity: 1,
                            color: i < avaliacao.estrelas ? '#ffc107' : '#e4e5e9',
                          }}
                          transition={{ delay: i * 0.2, duration: 0.5 }}
                        >
                          <FaStar />
                        </motion.span>
                      ))}
                    </div>
                    <div className="conteudo">
                      <p>{truncateText(avaliacao.comentario, 100)}</p>
                      {/* Exibir o objetivo se estiver disponível */}
                      {avaliacao.objetivo && (
                        <p><strong>Objetivo:</strong> {truncateText(avaliacao.objetivo, 50)}</p>
                      )}
                    </div>
                  </div>
                );
              })}
            </motion.div>
          )}
        </AnimatePresence>
        {slides.length > 1 && (
          <div className="carousel-controls">
            <button onClick={() => paginate(-1)} className="prev-button" aria-label="Anterior">
              <FaChevronLeft />
            </button>
            <button onClick={() => paginate(1)} className="next-button" aria-label="Próximo">
              <FaChevronRight />
            </button>
          </div>
        )}
      </div>
    </section>
  );
}