// HomePage.jsx

import React, { useEffect, useState } from 'react';
import Hero from './Hero';
import CardCompra from './CardCompra';
import Avaliacoes from './Avaliacoes';
import FAQ from './FAQ';
import Footer from './Footer';
import './HomePage.css'; // Criaremos este arquivo para estilos gerais

export default function HomePage({ lista_itens }) {
  const [showCardCompra, setShowCardCompra] = useState(false);

  const [codigoInserido, setCodigoInserido] = useState('');
  const [emailInserido, setEmailInserido] = useState('');
  const [etapa, setEtapa] = useState(1);
  const [avaliacoesData, setAvaliacoesData] = useState([]);
  const [cupomCode, setCupomCode] = useState(null);
  const [percentual, setPercentual] = useState(0); // Novo estado para o percentual

  const handleStartJourney = () => {
    // Scroll suave para o CardCompra
    const cardCompraSection = document.getElementById('card-compra-section');
    if (cardCompraSection) {
      cardCompraSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleLogin = (codigoAcesso, email) => {
    setCodigoInserido(codigoAcesso);
    setEmailInserido(email);
    setEtapa(5);

    // Scroll suave para o CardCompra após login
    const cardCompraSection = document.getElementById('card-compra-section');
    if (cardCompraSection) {
      cardCompraSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    const fetchAvaliacoes = async () => {
      try {
        const response = await fetch(`${backendUrl}/avaliacao/`);
        if (!response.ok) {
          throw new Error(`Erro: ${response.status} ${response.statusText}`);
        }
        const data = await response.json();
        console.log(data);
        setAvaliacoesData(data); // Popula as avaliações
      } catch (err) {
        console.error(err);
        // Opcional: setErro(err.message);
      }
    };

    const fetchCupom = async () => {
      try {
        const response = await fetch(`${backendUrl}/pedido/get_cupom_code_temp`);
        if (!response.ok) {
          throw new Error(`Erro ao obter cupom: ${response.status} ${response.statusText}`);
        }
        const data = await response.json();
        setCupomCode(data.cupom_code);
        setPercentual(data.percentual);
      } catch (err) {
        console.error(err);
        // Opcional: setErroCupom(err.message);
      }
    };

    fetchAvaliacoes();
    fetchCupom(); // Chama a função para buscar o cupom ao iniciar
  }, [backendUrl]);

  function getPrecoFinal(item, percentual_cupom){
    console.log(item.valor_off)
    var preco_final_diario = (item.valor_off - (item.valor_off * percentual_cupom / 100)) / 91
    return preco_final_diario.toFixed(2)
  }

  return (
    <div className="homepage">
      <Hero
        onStart={handleStartJourney}
        haveCupom={cupomCode != null}
        cupom_code={cupomCode}
        percentual={percentual} // Passa o percentual dinâmico
        handleLogin={handleLogin} // Passando handleLogin para Hero
        preco_diario={getPrecoFinal(lista_itens[0], percentual)}
      />

      {/* Seção Sobre o Produto */}
      <section className="sobre-produto container-padded">
        <h2>Sobre Nossa Assessoria</h2>
        <p>
          Nossa assessoria oferece um plano completo de dieta, nutrição e treino, personalizado com a ajuda da nossa IA inteligente, <strong>NutriAI</strong>. Com base nos seus dados e preferências, criamos um programa de 90 dias que se adapta ao seu estilo de vida, seja você um iniciante ou um atleta experiente.
        </p>
      </section>

      {/* Seção Como Funciona */}
      <section className="como-funciona container-padded">
        <h2>Como Funciona</h2>
        <div className="steps">
          <div className="step">
            <h3>1. Insira Seus Dados</h3>
            <p>Preencha informações sobre sua dieta, preferências alimentares, rotina de treinos e objetivos.</p>
          </div>
          <div className="step">
            <h3>2. Análise da IA</h3>
            <p>Nossa IA, <strong>NutriAI</strong>, processa seus dados para criar um plano personalizado para você.</p>
          </div>
          <div className="step">
            <h3>3. Receba Seu Plano</h3>
            <p>Receba um plano detalhado de 90 dias com dietas, treinos e instruções para alcançar seus objetivos.</p>
          </div>
        </div>
      </section>

      {/* Seção Depoimentos */}
      <Avaliacoes avaliacoes={avaliacoesData} />

      {/* Seção Detalhes da Oferta */}
      <section className="detalhes-oferta container-padded">
        <h2>Oferta Especial</h2>
        <p>
          Por menos de <strong>15 centavos por dia</strong>, você recebe uma assessoria completa que pode economizar até <strong>50 vezes</strong> em relação a planos tradicionais. Invista no seu bem-estar e transforme sua vida com nosso suporte especializado.
        </p>
      </section>

      {/* Seção CardCompra */}
      <section id="card-compra-section" className="container-padded">
        <CardCompra
          lista_itens={lista_itens}
          codigoInserido={codigoInserido} // Passando codigoInserido como prop
          emailInserido={emailInserido}
          etapa={etapa} // Passando etapa como prop
          setEtapa={setEtapa} // Passando setEtapa para permitir atualizações internas, se necessário
          cupom_code={cupomCode} // Passando cupom_code como prop
          percentual={percentual} // Passando percentual como prop
        />
      </section>

      {/* Seção FAQ */}
      <FAQ />

      {/* Rodapé */}
      <Footer />
    </div>
  );
}