// CardPagamento.jsx
import React, { useEffect, useState, useRef } from 'react';
import './CardPagamento.css';

export default function CardPagamento({ pagamento, mensagemCompra, onPagamentoConfirmado }) {
  const { id_pedido, qr_code_base64, copia_cola, valor_total, email } = pagamento;
  const [mensagemCompraPadrao, setMensagemCompraPadrao] = useState(mensagemCompra || "Agradecemos sua compra. Prepare-se para transformar seu corpo!");
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  
  // Estado para o timer
  const [timeLeft, setTimeLeft] = useState(30 * 60); // 30 minutos em segundos

  // Estado para controlar a cópia
  const [isCopied, setIsCopied] = useState(false);

  // Ref para o textarea
  const textareaRef = useRef(null);

  useEffect(() => {
    // Atualiza o timer a cada segundo
    const timer = setInterval(() => {
      setTimeLeft(prevTime => {
        if (prevTime <= 1) {
          clearInterval(timer);
          // Lógica opcional quando o tempo acabar
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    // Limpa o timer ao desmontar o componente
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    // Ajusta a altura do textarea com base no conteúdo
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [copia_cola]);

  // Formata o tempo restante para mm:ss
  const formatTime = (seconds) => {
    const m = Math.floor(seconds / 60).toString().padStart(2, '0');
    const s = (seconds % 60).toString().padStart(2, '0');
    return `${m}:${s}`;
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(copia_cola)
      .then(() => {
        setIsCopied(true);
        // Reseta após 3 segundos
        setTimeout(() => setIsCopied(false), 3000);
      })
      .catch(() => {
        alert('Falha ao copiar o código.');
      });
  };

  // Função para validar o pagamento
  const validarPagamento = async () => {
    try {
      const response = await fetch(`${backendUrl}/pedido/validar_pagamento`, { // Atualize a URL conforme necessário
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id_pedido, email }),
      });

      if (!response.ok) {
        throw new Error('Erro ao validar o pagamento');
      }

      const data = await response.json();
      if (data.status_pagamento === true) { // Ajuste conforme a resposta real
        clearInterval(pollingInterval.current);
        onPagamentoConfirmado(); // Função callback para notificar o componente pai
      }
    } catch (error) {
      console.error('Erro ao validar o pagamento:', error);
      // Opcional: tratar erros específicos
    }
  };

  // Ref para armazenar o intervalo de polling
  const pollingInterval = useRef(null);

  useEffect(() => {
    // Inicia o polling a cada 10 segundos
    pollingInterval.current = setInterval(validarPagamento, 5000); // 10000 ms = 10 segundos

    // Limpa o intervalo ao desmontar o componente
    return () => clearInterval(pollingInterval.current);
  }, []);

  return (
    <div className="card-pagamento">
      {/* Cabeçalho de Sucesso */}
      <div className="sucesso-header">
        <h2>Pedido <strong>{id_pedido}</strong> Confirmado!</h2>
        <p>{mensagemCompraPadrao}</p>
      </div>

      {/* QR Code */}
      <div className="qr-code-container">
        <img src={`data:image/png;base64,${qr_code_base64}`} alt="QR Code de Pagamento" className="qr-code" />
      </div>

      {/* PIX Cópia e Cola com Timer */}
      <div className="pix-container">
        <div className="pix-header">
          <label htmlFor="pix-copia-cola" className="pix-label">PIX Cópia e Cola</label>
          <div className="timer-clock">
            <svg xmlns="http://www.w3.org/2000/svg" className="clock-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <circle cx="12" cy="12" r="10"></circle>
              <polyline points="12 6 12 12 16 14"></polyline>
            </svg>
            <span className="timer-text">{formatTime(timeLeft)}</span>
          </div>
        </div>
        <textarea
          id="pix-copia-cola"
          value={copia_cola}
          readOnly
          className="pix-textarea"
          rows="1"
          ref={textareaRef}
        />
        <button
          onClick={handleCopy}
          className={`botao-copiar ${isCopied ? 'copiado' : ''}`}
        >
          {isCopied ? 'Copiado!' : 'Copiar Código'}
        </button>
      </div>

      {/* Valor Total */}
      <div className="valor-total">
        <span className="label">Total:</span>
        <span className="valor">{valor_total}</span>
      </div>

      {/* Legenda de Segurança */}
      <p className="seguranca-legend">
        Pagamento seguro via <strong>Mercado Pago</strong>. Detalhes do pedido enviados para <strong>{email}</strong>.
      </p>
    </div>
  );
}