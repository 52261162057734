// AreaClienteInput.jsx

import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './AreaClienteInput.css';

export default function AreaClienteInput({ handleLogin }) { // Recebendo handleLogin como prop
  const [expanded, setExpanded] = useState(false);
  const [codeStep, setCodeStep] = useState(false);
  const [email, setEmail] = useState('');
  const [codigoAcesso, setCodigoAcesso] = useState('');
  const [mensagem, setMensagem] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const onClickEnviarCodigo = async () => {
    try {
      const response = await fetch(`${backendUrl}/login/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 'email': email, "codigo_acesso": codigoAcesso }),
      });

      const data = await response.json();

      if (response.ok) {
        // Contrair o formulário
        setCodeStep(false);
        setExpanded(false);
        setSuccessMessage('');
        // Chamar handleLogin com o código de acesso e email
        handleLogin(codigoAcesso, email);
        // Limpar os campos
        setEmail('');
        setCodigoAcesso('');
      } else {
        setMensagem(data.message || 'Erro ao enviar email');
        setSuccessMessage('');
      }

    } catch (error) {
      console.error('Erro ao enviar código de acesso:', error);
      setMensagem('Ocorreu um erro ao tentar realizar o login. Por favor, tente novamente.');
      setSuccessMessage('');
    }
  }

  const onClickEnviarEmail = async () => {
    try {
      setSuccessMessage(`Se este e-mail estiver cadastrado, enviamos um código de acesso para: ${email}. Caso não o receba em alguns minutos, verifique também a sua caixa de spam.`);
      setCodeStep(true);
      const response = await fetch(`${backendUrl}/login/recovery`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (response.ok) {
        // Opcional: Você pode adicionar lógica adicional aqui, se necessário
      } else {
        // Opcional: Tratar outros estados de resposta
      }
    } catch (error) {
      // Opcional: Tratar erros
      console.error('Erro ao enviar email de recuperação:', error);
    }
  };

  const onCloseLogin = () => {
    setExpanded(false);
    setCodeStep(false);
    setSuccessMessage('');
    setMensagem('');
    setEmail('');
    setCodigoAcesso('');
  };

  return (
    <div className="area-cliente-minimal">
      <div className={`area-cliente-wrapper ${expanded || codeStep ? 'expanded' : ''}`}>
        <AnimatePresence mode="wait">
          {!expanded && !codeStep && (
            <motion.button
              key="botaoAreaCliente"
              className="area-cliente-button-minimal"
              onClick={() => setExpanded(true)}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              Área Cliente
            </motion.button>
          )}

          {expanded && !codeStep && (
            <motion.div
              key="emailStep"
              className="area-cliente-form-row"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              <button className="area-cliente-cancel-btn" onClick={onCloseLogin}>
                ✕
              </button>
              <input
                type="email"
                placeholder="Seu email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="area-cliente-input"
              />
              <button className="area-cliente-send-btn" onClick={onClickEnviarEmail}>
                ➜
              </button>
            </motion.div>
          )}

          {expanded && codeStep && (
            <motion.div
              key="codigoStep"
              className="area-cliente-form-row"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              <button className="area-cliente-cancel-btn" onClick={onCloseLogin}>
                ✕
              </button>
              <input
                type="text"
                placeholder="Código de acesso"
                value={codigoAcesso}
                onChange={(e) => setCodigoAcesso(e.target.value)}
                className="area-cliente-input"
              />
              <button className="area-cliente-send-btn" onClick={onClickEnviarCodigo}>
                ➜
              </button>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      {/* Mensagem de sucesso fora do wrapper */}
      {successMessage && (
        <motion.div
          className="area-cliente-mensagem-sucesso"
          initial={{ opacity: 0, y: -5 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -5 }}
          transition={{ duration: 0.3 }}
        >
          {successMessage}
        </motion.div>
      )}

      {/* Mensagem de erro */}
      {mensagem && (
        <motion.div
          className="area-cliente-mensagem-erro"
          initial={{ opacity: 0, y: -5 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -5 }}
          transition={{ duration: 0.3 }}
        >
          {mensagem}
        </motion.div>
      )}
    </div>
  );
}