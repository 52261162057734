import React, { useEffect, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import './PromptInput.css';

export default function PromptInput({ 
  value, 
  onChange, 
  suggestionsList,
  labelInfo = "Toda informação colocada é restrita de ACESSO apenas a você, não salvamos dados pessoais, somente e-mail para envio e recuperação de conta" 
}) {
  const [suggestionIndex, setSuggestionIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSuggestionIndex((prevIndex) => (prevIndex + 1) % suggestionsList.length);
    }, 3000); // muda a sugestão a cada 3 segundos
    return () => clearInterval(intervalId);
  }, [suggestionsList.length]);

  return (
    <div className="prompt-container">
      <label className="prompt-label">
        {labelInfo}
      </label>
      <div className="prompt-input-wrapper">
        <TextareaAutosize
          minRows={3}
          maxRows={10}
          className="prompt-textarea"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder="Digite todos os detalhes que ajudem em sua acessoria..."
        />
        <div className="prompt-cursor" />
      </div>
      <div className="prompt-suggestion">
        {suggestionsList[suggestionIndex]}
      </div>
    </div>
  );
}