// src/components/BotaoComprar.jsx
import React from "react";
import "./BotaoComprar.css";

export default function BotaoComprar({ onComprar, label = "Quero iniciar minha jornada!", animated = false }) {
  return (
    <button className={`botao-comprar ${animated ? 'animated' : ''}`} onClick={onComprar}>
      {label}
    </button>
  );
}