import React, { useEffect, useState, useRef } from 'react';
import './CardItensComprados.css';
import { motion } from 'framer-motion';
import { FaDownload, FaRedo } from 'react-icons/fa';

export default function CardItensComprados({ codigoAcesso, email }) {
  const [itensComprados, setItensComprados] = useState([]);
  const [valorTotal, setValorTotal] = useState(0);
  const [downloadStatus, setDownloadStatus] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const pollingIntervals = useRef({}); // Armazena os intervalos de polling para cada item
  const retryCount = useRef(0); // Conta o número de tentativas
  const maxRetries = 5; // Número máximo de tentativas

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  // ================================
  // 1. Carrega itens na montagem com Retry
  // ================================
  useEffect(() => {
    let isMounted = true; // Para evitar atualizações de estado após o desmontamento

    const fetchItens = async () => {
      try {
        const response = await fetch(`${backendUrl}/login/`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ codigo_acesso: codigoAcesso, email }),
        });
        if (!response.ok) {
          throw new Error('Erro ao buscar itens.');
        }
        const data = await response.json();
        if (isMounted) {
          setItensComprados(data.itens_comprados);
          setValorTotal(data.valor_total);
          setIsLoading(false);
          setError(null);
          retryCount.current = 0; // Resetar contador de tentativas após sucesso
        }
      } catch (err) {
        console.error('Erro ao buscar itens:', err);
        if (isMounted) {
          if (retryCount.current < maxRetries) {
            retryCount.current += 1;
            setTimeout(fetchItens, 2000); // Tentar novamente em 2 segundos
          } else {
            setError('Não foi possível carregar os itens. Por favor, tente novamente mais tarde.');
            setIsLoading(false);
            console.log('Número máximo de tentativas atingido.');
          }
        }
      }
    };

    fetchItens();

    return () => {
      isMounted = false;
      // Limpar todos os intervalos de polling ao desmontar
      Object.values(pollingIntervals.current).forEach((intervalId) => {
        if (intervalId) clearInterval(intervalId);
      });
    };
  }, [backendUrl, codigoAcesso, email]);

  // ================================
  // 2. Funções de Utilidade
  // ================================

  // Função para obter o label do botão
  const getButtonLabel = (item) => {
    const status = downloadStatus[item.id_item];
    if (!status) return 'Gerar';
    if (status.error) return 'Erro';
    if (status.percentual < 100) return `Gerando...%)`;
    return 'Baixar';
  };

  // Função para obter a mensagem com base no percentual
  const getProgressMessage = (percentual) => {
    if (percentual < 10) return 'Iniciando... 💪';
    if (percentual >= 10 && percentual < 40) return 'Preparando Treinos... 🏋️‍♂️';
    if (percentual >= 40 && percentual < 70) return 'Criando Dietas... 🍎';
    if (percentual >= 70 && percentual < 100) return 'Finalizando Detalhes... 🔍';
    if (percentual === 100) return 'Pronto para Download! 🚀';
    return '';
  };

  // ================================
  // 3. Manipulador de Clique
  // ================================
  const handleClick = async (item) => {
    const currentStatus = getButtonLabel(item);
    console.log(`Clique no botão para item ${item.id_item}: ${currentStatus}`);

    // Se já estiver "Baixar", direciona para o download
    if (currentStatus === 'Baixar') {
      console.log(`Baixando item ${item.id_pedido}`);
      const link = document.createElement('a');
      link.href = `${backendUrl}/prompt/download_pdf/${item.id_pedido}`;
      link.setAttribute('download', `acessoria-${item.id_pedido}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return;
    }

    // Se está em processo (Gerando...), evita duplo clique
    if (currentStatus.startsWith('Gerando')) {
      console.log(`Item ${item.id_item} já está em processo de geração.`);
      return;
    }

    // Define status inicial
    setDownloadStatus((prev) => ({
      ...prev,
      [item.id_item]: { percentual: 0, downloadLink: null, error: null },
    }));
    console.log(`Iniciando geração para item ${item.id_item}`);

    try {
      // Iniciar a geração de assessoria
      const response = await fetch(`${backendUrl}/prompt/gerar_acessoria`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          id_item: item.id_item,
          id_pedido: item.id_pedido,
        }),
      });

      const data = await response.json();
      console.log(`Resposta da geração para item ${item.id_item}:`, data);

      if (!response.ok) {
        throw new Error(data.error || 'Erro ao iniciar a geração.');
      }

      // Atualiza o percentual inicial se fornecido
      if (data.percentual !== undefined) {
        const percentualNumber = parseFloat(data.percentual);
        setDownloadStatus((prev) => ({
          ...prev,
          [item.id_item]: { ...prev[item.id_item], percentual: percentualNumber },
        }));
        console.log(`Percentual inicial para item ${item.id_item}: ${percentualNumber}%`);
      }

      // Iniciar polling a cada 8 segundos
      pollingIntervals.current[item.id_item] = setInterval(async () => {
        try {
          const pollResponse = await fetch(`${backendUrl}/prompt/gerar_acessoria`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              id_item: item.id_item,
              id_pedido: item.id_pedido,
            }),
          });

          const pollData = await pollResponse.json();
          console.log(`Resposta do polling para item ${item.id_item}:`, pollData);

          if (!pollResponse.ok) {
            throw new Error(pollData.error || 'Erro ao obter o progresso.');
          }

          let { percentual } = pollData;
          percentual = parseFloat(percentual);

          setDownloadStatus((prev) => ({
            ...prev,
            [item.id_item]: { ...prev[item.id_item], percentual, error: null },
          }));

          console.log(`Item ${item.id_item} - Percentual atualizado: ${percentual}%`);

          if (percentual >= 100) {
            clearInterval(pollingIntervals.current[item.id_item]);
            pollingIntervals.current[item.id_item] = null;

            const downloadLink = `${backendUrl}/prompt/download_pdf/${item.id_pedido}`;
            setDownloadStatus((prev) => ({
              ...prev,
              [item.id_item]: { percentual: 100, downloadLink, error: null },
            }));
            console.log(`Geração completa para item ${item.id_item}`);
          }
        } catch (err) {
          console.error('Erro ao verificar o progresso:', err);
          clearInterval(pollingIntervals.current[item.id_item]);
          pollingIntervals.current[item.id_item] = null;
          setDownloadStatus((prev) => ({
            ...prev,
            [item.id_item]: { percentual: prev[item.id_item]?.percentual || 0, error: err.message, downloadLink: null },
          }));
        }
      }, 8000); // 8 segundos
    } catch (err) {
      console.error('Erro ao iniciar a tarefa:', err);
      setDownloadStatus((prev) => ({
        ...prev,
        [item.id_item]: { percentual: 0, downloadLink: null, error: err.message },
      }));
    }
  };

  // Limpar os intervalos quando o componente desmonta
  useEffect(() => {
    return () => {
      Object.values(pollingIntervals.current).forEach((intervalId) => {
        if (intervalId) clearInterval(intervalId);
      });
    };
  }, []);

  // ================================
  // 4. Renderização do Componente
  // ================================
  return (
    <div className="card-itens-comprados">
      <div className="header">
        {isLoading && <h2>Carregando itens... ⏳</h2>}
        {error && <h2 className="error-message">{error} ❌</h2>}
        {!isLoading && !error && itensComprados.length > 0 && (
          <h2>
            Pedido #{itensComprados[0].id_pedido} efetuado e pagamento confirmado!
          </h2>
        )}
      </div>

      <div className="itens-lista">
        {isLoading && (
          <motion.div
            className="loading-container"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <span className="loading-spinner"></span>
            <p>Carregando itens... Por favor, aguarde.</p>
          </motion.div>
        )}
        {!isLoading && error && null}
        {!isLoading && !error && itensComprados.length > 0 ? (
          itensComprados.map((item) => {
            const status = downloadStatus[item.id_item];
            const labelBotao = getButtonLabel(item);
            const isExpanded = labelBotao !== 'Gerar';

            return (
              <motion.div 
                key={item.id_item} 
                className="item"
                layout
                transition={{ duration: 0.6 }}
              >
                {/* Parte “compacta” */}
                <div className="item-compact">
                  <div className="item-info">
                    <span className="nome">{item.descricao}</span>
                  </div>
                  <div className="item-preco">
                    {new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(item.valor)}
                  </div>
                  <div className="item-download">
                    <button
                      onClick={() => handleClick(item)}
                      className={
                        status
                          ? status.error
                            ? 'btn-erro'
                            : status.percentual < 100
                              ? 'btn-gerando'
                              : 'btn-baixar'
                          : ''
                      }
                    >
                      {status && status.error ? (
                        <>
                          <FaRedo style={{ marginRight: '4px' }} />
                          Tentar Novamente
                        </>
                      ) : status && status.percentual >= 100 ? (
                        <>
                          <FaDownload style={{ marginRight: '4px' }} />
                          Baixar
                        </>
                      ) : status && status.percentual < 100 ? (
                        <>
                          Gerando...
                          <span className="spinner" />
                        </>
                      ) : (
                        'Gerar'
                      )}
                    </button>
                  </div>
                </div>

                {/* Card expandido (barra de progresso) */}
                {isExpanded && status && (
                  <motion.div
                    className="item-expanded"
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: 'auto', opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.6 }}
                    layout
                  >
                    {/* Barra de progresso */}
                    <div className="progress-bar-container">
                      <motion.div
                        className="progress-bar"
                        initial={{ width: 0 }}
                        animate={{ width: `${status.percentual}%` }}
                        transition={{ duration: 7, ease: 'easeInOut' }}
                      >
                        <motion.span
                          className="progress-percent"
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          transition={{ duration: 7, ease: 'easeInOut' }}
                        >
                          {status.percentual.toFixed(2)}%
                        </motion.span>
                      </motion.div>
                    </div>

                    {/* Texto da etapa atual */}
                    <div className="etapa-texto">
                      {getProgressMessage(status.percentual)}
                    </div>
                  </motion.div>
                )}
              </motion.div>
            );
          })
        ) : (
          !isLoading && !error && <p>Nenhum item encontrado.</p>
        )}
      </div>

      <div className="valor-total">
        <span className="label">Total:</span>
        <span className="valor">
          {new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(valorTotal)}
        </span>
      </div>
    </div>
  );
}