import React, { useEffect, useRef } from 'react';
import './CardValorTotal.css'; 

export default function CardValorTotal({ itens, itensSelecionados }) {
  const totalSelecionados = itens.reduce((acc, item) => {
    if (itensSelecionados.includes(item.id_item)) {
      return acc + item.valor_off;
    }
    return acc;
  }, 0);

  const formatCurrency = (value) => {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  };

  const cardRef = useRef(null);

  useEffect(() => {
    // Adiciona classe para animar quando o valor mudar
    cardRef.current.classList.add('fade-in');
    const timer = setTimeout(() => {
      cardRef.current.classList.remove('fade-in');
    }, 500); 
    return () => clearTimeout(timer);
  }, [totalSelecionados]);

  return (
    <div className="card-valor-total" ref={cardRef}>
      <span className="label">Valor Total</span>
      <strong className="valor">{formatCurrency(totalSelecionados)}</strong>
      <span className="pagamento-unico">Pagamento único</span>
    </div>
  );
}