import React from 'react';
import './DailyPriceBadge.css';

const DailyPriceBadge = ({ price }) => {
  return (
    <div className="daily-price-badge">
      <h2>Menos de {price} por DIA!</h2>
    </div>
  );
};

export default DailyPriceBadge;