import React, { useState, useEffect } from 'react';
import './InputPersonalizado.css';
import PropTypes from 'prop-types';

const InputPersonalizado = ({ 
  icon, 
  flexButton, 
  placeholder, 
  onClickButton, 
  type, 
  textButtom, 
  disabled,
  onChangeValue,
  label,
  upperCase,
  disableEdit,
  value, // Adiciona a prop 'value'
  textIfValue // Opcional: texto a ser exibido quando 'value' estiver presente
}) => {
  const [personalizado, setPersonalizado] = useState(value || '');

  useEffect(() => {
    if (value !== undefined) {
      const formattedValue = upperCase ? value.toString().toUpperCase() : value;
      setPersonalizado(formattedValue);
    }
  }, [value, upperCase]);

  const handleChange = (e) => {
    const inputValue = upperCase ? e.target.value.toString().toUpperCase() : e.target.value;
    setPersonalizado(inputValue);
    if (onChangeValue) {
      onChangeValue(inputValue);
    }
  };

  return (
    <div className="input-personalizado-container">
      {label && <div className="input-label">{label}</div>}
      <div className="input-personalizado">
        <input
          type={type}
          placeholder={placeholder}
          className="personalizado-input"
          value={personalizado}
          onChange={handleChange}
          readOnly={disableEdit} // Define como readOnly se disableEdit for true
        />
        {(textButtom || icon) && (
          <button
            style={{ flex: flexButton }}
            value={personalizado}
            className={`personalizado-button ${
              disabled ? 'disabled' : ''
            } ${textButtom && icon ? 'button-with-text-icon' : 'button-single-content'}`}
            onClick={!disabled ? onClickButton : undefined}
            disabled={disabled}
          >
            {textButtom && <span className="button-text">{textButtom}</span>}
            {icon && <span className="button-icon">{icon}</span>}
          </button>
        )}
      </div>
      {disableEdit && value && textIfValue && (
        <div className="value-text">
          {textIfValue}
        </div>
      )}
    </div>
  );
};

InputPersonalizado.propTypes = {
  icon: PropTypes.element,
  flexButton: PropTypes.number,
  placeholder: PropTypes.string.isRequired,
  onClickButton: PropTypes.func,
  type: PropTypes.string.isRequired,
  textButtom: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  upperCase: PropTypes.bool,
  disableEdit: PropTypes.bool,
  value: PropTypes.string, // Define o propType para 'value'
  textIfValue: PropTypes.string // Opcional: define o propType para texto adicional
};

InputPersonalizado.defaultProps = {
  onClickButton: () => {},
  disabled: false,
  flexButton: 5,
  label: '',
  upperCase: false,
  disableEdit: false,
  value: '',
  textIfValue: ''
};

export default InputPersonalizado;  