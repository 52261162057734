import React, { useState, useEffect } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';
import './FAQ.css'; // Estilos CSS

export default function FAQ() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL; // URL do backend
  const [perguntas, setPerguntas] = useState([]); // Estado para armazenar as FAQs
  const [aberto, setAberto] = useState(null); // Estado para rastrear a pergunta aberta
  const [loading, setLoading] = useState(true); // Estado de carregamento
  const [error, setError] = useState(null); // Estado de erro

  // Função para buscar FAQs do backend
  const fetchFAQs = async () => {
    try {
      const response = await fetch(`${backendUrl}/avaliacao/faq`); // Chamada para a API
      if (!response.ok) {
        throw new Error('Erro ao buscar as perguntas frequentes!');
      }
      const data = await response.json();
      setPerguntas(data); // Atualiza o estado com os dados recebidos
    } catch (err) {
      setError(err.message); // Atualiza o estado de erro
    } finally {
      setLoading(false); // Finaliza o carregamento
    }
  };

  // Efeito para carregar FAQs ao montar o componente
  useEffect(() => {
    fetchFAQs();
  }, []); // Executa apenas uma vez ao montar o componente

  // Função para abrir e fechar respostas
  const toggleFAQ = (index) => {
    setAberto(aberto === index ? null : index);
  };

  // Exibe mensagem de carregamento
  if (loading) {
    return <p>Carregando perguntas frequentes...</p>;
  }

  // Exibe mensagem de erro
  if (error) {
    return <p>Erro: {error}</p>;
  }

  return (
    <section className="faq">
      <h2>Perguntas Frequentes</h2>
      <div className="faq-container">
        {perguntas.map((item, index) => (
          <div key={item.id_faq} className="faq-item"> {/* Usa o ID como chave */}
            <div className="faq-pergunta" onClick={() => toggleFAQ(index)}>
              <h3>{item.pergunta}</h3>
              {aberto === index ? <FaMinus /> : <FaPlus />}
            </div>
            {aberto === index && <div className="faq-resposta"><p>{item.resposta}</p></div>}
          </div>
        ))}
      </div>
    </section>
  );
}