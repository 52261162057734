import React from 'react';
import './SaudacoesCliente.css';
import { AnimatePresence, motion } from 'framer-motion';

export default function SaudacoesCliente({ nome, saudacaoEspecial }) {
  // Agora não truncamos a saudação, exibimos o texto completo retornado.
  const displaySaudacao = saudacaoEspecial || null;

  // Animação: fade in/out suave
  const variants = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 }
  };

  return (
    <div className="saudacoes-container">
      <AnimatePresence mode="wait">
        {!displaySaudacao ? (
          <motion.div 
            key="default-saudacao"
            initial="initial"
            animate="animate"
            exit="exit"
            variants={variants}
            transition={{ duration: 0.4 }}
            className="saudacoes-content"
          >
            <h2 className="saudacao-titulo">
              Nos conte mais sobre Você{nome ? `, ${nome}` : ''}
            </h2>
            <p className="saudacao-subtitulo">
              Queremos entender suas necessidades para oferecer o melhor plano de assessoria
            </p>
          </motion.div>
        ) : (
          <motion.div
            key="especial-saudacao"
            initial="initial"
            animate="animate"
            exit="exit"
            variants={variants}
            transition={{ duration: 0.4 }}
            className="saudacoes-content"
          >
            <h2 className="saudacao-titulo">{displaySaudacao}</h2>
            <p className="saudacao-subtitulo">Ficamos felizes em ter você aqui!</p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}