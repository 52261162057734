// src/components/AnimatedAssistant.jsx
import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { FaRobot, FaRegQuestionCircle, FaPaperPlane, FaTimes } from 'react-icons/fa';



const AssistantContainer = styled(motion.div)`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: ${({ isOpen }) => (isOpen ? '320px' : '60px')};
  height: ${({ isOpen }) => (isOpen ? '400px' : '60px')};
  background-color: #ff7f50;
  border-radius: ${({ isOpen }) => (isOpen ? '15px' : '50%')};
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  z-index: 1000;
  transition: width 0.3s ease, height 0.3s ease, border-radius 0.3s ease;

  @media (max-width: 768px) {
    width: ${({ isOpen }) => (isOpen ? '90%' : '60px')};
    right: 5%;
    bottom: 10px;
    height: ${({ isOpen }) => (isOpen ? '400px' : '60px')};
  }
`;

const Header = styled.div`
  background-color: #ff7f50;
  color: #ffffff;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.span`
  font-weight: bold;
  font-size: 16px;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: #ffffff;
  font-size: 20px;
  cursor: pointer;
`;

const Content = styled(motion.div)`
  flex: 1;
  background-color: #ffffff;
  padding: 15px;
  overflow-y: auto;
`;

const Question = styled(motion.div)`
  background-color: #f9f9f9;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Alinhamento à direita */
  cursor: pointer;

  &:hover {
    background-color: #f1f1f1;
  }
`;

const Answer = styled.div`
  background-color: #e6f7ff;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;
`;

const AssistantButtonContent = styled(motion.div)`
  color: #ffffff;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const InputContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const Input = styled.input`
  flex: 1;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 5px 0 0 5px;
  outline: none;

  &:focus {
    border-color: #ff7f50;
  }
`;

const SubmitButton = styled.button`
  padding: 8px;
  background-color: #ff7f50;
  color: #ffffff;
  border: none;
  border-radius: 0 5px 5px 0;
  cursor: pointer;

  &:hover {
    background-color: #e67348;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const UserQuestion = styled.div`
  background-color: #fffbe6;
  padding: 10px;
  border-radius: 8px;
  margin-top: 10px;
`;

const UserResponse = styled.div`
  background-color: #e6ffed;
  padding: 10px;
  border-radius: 8px;
  margin-top: 10px;
`;

const AnimatedAssistant = () => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const [isOpen, setIsOpen] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [userQuestion, setUserQuestion] = useState('');
  const [userResponse, setUserResponse] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [faqs, setFaqs] = useState([])

  const toggleAssistant = () => {
    setIsOpen(!isOpen);
    setSelectedAnswer(null);
    setUserResponse(null);
  };

  const handleQuestionClick = (faq) => {
    setSelectedAnswer(faq.resposta);
    setUserResponse(null);
  };

  const handleUserQuestionSubmit = async () => {
    if (userQuestion.trim() === '') return;

    setIsSubmitting(true);
    // Simulação de chamada API
    await new Promise((resolve) => setTimeout(resolve, 1000));

    // Aqui você pode integrar com uma API real para processar a pergunta do usuário
    // Por enquanto, vamos apenas exibir uma resposta estática
    setUserResponse("Obrigado por sua pergunta! Nossa equipe entrará em contato em breve.");
    setUserQuestion('');
    setIsSubmitting(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !isSubmitting) {
      handleUserQuestionSubmit();
    }
  };
  useEffect(() => {
    const fetchFAQs = async () => {
      try {
        const response = await fetch(`${backendUrl}/avaliacao/faq`);
        if (!response.ok) {
          throw new Error('Erro ao carregar FAQs!');
        }
        const data = await response.json();
        setFaqs(data); // Atualiza as perguntas
      } catch (err) {
        // setError(err.message); // Armazena erros
      } finally {
        // setLoading(false); // Finaliza carregamento
      }
    };
    fetchFAQs();
  }, [backendUrl]);

  return (
    <AssistantContainer
      isOpen={isOpen}
      onClick={!isOpen ? toggleAssistant : undefined}
      role="button"
      aria-label={isOpen ? "Fechar assistente" : "Abrir assistente"}
      tabIndex={0}
      onKeyPress={(e) => {
        if (e.key === 'Enter') toggleAssistant();
      }}
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      transition={{ type: 'spring', stiffness: 300, damping: 20 }}
    >
      <AnimatePresence>
        {isOpen ? (
          <>
            <Header>
              <Title>Assistente</Title>
              <CloseButton onClick={toggleAssistant} aria-label="Fechar Assistente">
                <FaTimes />
              </CloseButton>
            </Header>
            <Content
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              {selectedAnswer ? (
                <>
                  <Answer>{selectedAnswer}</Answer>
                  <Question
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    onClick={() => setSelectedAnswer(null)}
                  >
                    <span>Voltar para perguntas</span>
                    <FaTimes />
                  </Question>
                </>
              ) : userResponse ? (
                <>
                  <UserResponse>{userResponse}</UserResponse>
                  <Question
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    onClick={() => setUserResponse(null)}
                  >
                    <span>Fazer outra pergunta</span>
                    <FaRegQuestionCircle />
                  </Question>
                </>
              ) : (
                <>
                  {faqs.map((faq, index) => (
                    <Question
                      key={index}
                      onClick={() => handleQuestionClick(faq)}
                      initial={{ opacity: 0, x: 50 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: index * 0.1 }}
                      whileHover={{ backgroundColor: '#e0e0e0' }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <span>{faq.pergunta}</span>
                      <FaRegQuestionCircle />
                    </Question>
                  ))}
                  {/* Campo de "Outra dúvida" */}
                  <InputContainer>
                    <span>Tem outra dúvida? Digite abaixo:</span>
                    <InputWrapper>
                      <Input
                        type="text"
                        value={userQuestion}
                        onChange={(e) => setUserQuestion(e.target.value)}
                        onKeyPress={handleKeyPress}
                        placeholder="Digite sua pergunta..."
                        aria-label="Digite sua pergunta"
                      />
                      <SubmitButton
                        onClick={handleUserQuestionSubmit}
                        disabled={userQuestion.trim() === '' || isSubmitting}
                        aria-label="Enviar pergunta"
                      >
                        <FaPaperPlane />
                      </SubmitButton>
                    </InputWrapper>
                    {userResponse && <UserResponse>{userResponse}</UserResponse>}
                  </InputContainer>
                </>
              )}
            </Content>
          </>
        ) : (
          <AssistantButtonContent
            animate={{
              rotate: [0, 20, -20, 0],
            }}
            transition={{
              repeat: Infinity,
              duration: 2,
            }}
          >
            <FaRobot size={30} />
          </AssistantButtonContent>
        )}
      </AnimatePresence>
    </AssistantContainer>
  );
};

export default AnimatedAssistant;